/*
    Autogenerated file containing the variables
    from the figma file
*/

/* stylelint-disable -- this file is the only one with hardcoded values, invalid variable names, ... */

// Primitives variables (TO NOT USE)
$__color-neutral-scale-50: #fafafaff;
$__color-neutral-scale-100: #f5f5f5ff;
$__color-neutral-scale-200: #eeeeeeff;
$__color-neutral-scale-300: #e0e0e0ff;
$__color-neutral-scale-400: #bdbdbdff;
$__color-neutral-scale-500: #9e9e9eff;
$__color-neutral-scale-600: #757575ff;
$__color-neutral-scale-700: #616161ff;
$__color-neutral-scale-800: #424242ff;
$__color-neutral-scale-900: #212121ff;
$__color-neutral-scale-1000: #000000ff;
$__color-error-scale-50: #ffebeeff;
$__color-neutral-scale-0: #ffffffff;
$__color-error-scale-100: #ffcdd2ff;
$__color-error-scale-200: #ef9a9aff;
$__color-error-scale-300: #e57373ff;
$__color-error-scale-400: #ef5350ff;
$__color-error-scale-500: #f44336ff;
$__color-error-scale-600: #e53935ff;
$__color-error-scale-700: #d32f2fff;
$__color-error-scale-800: #c62828ff;
$__color-error-scale-900: #b71c1cff;
$__color-warning-scale-50: #fff3e0ff;
$__color-warning-scale-100: #ffe0b2ff;
$__color-warning-scale-200: #ffcc80ff;
$__color-warning-scale-300: #ffb74dff;
$__color-warning-scale-400: #ffa726ff;
$__color-warning-scale-500: #ff9800ff;
$__color-warning-scale-600: #fb8c00ff;
$__color-warning-scale-700: #f57c00ff;
$__color-warning-scale-800: #ef6c00ff;
$__color-warning-scale-900: #e65100ff;
$__color-confirmation-scale-50: #e8f5e9ff;
$__color-confirmation-scale-100: #c8e6c9ff;
$__color-confirmation-scale-200: #a5d6a7ff;
$__color-confirmation-scale-300: #81c784ff;
$__color-confirmation-scale-400: #66bb6aff;
$__color-confirmation-scale-500: #4caf50ff;
$__color-confirmation-scale-600: #43a047ff;
$__color-confirmation-scale-700: #388e3cff;
$__color-confirmation-scale-800: #2e7d32ff;
$__color-confirmation-scale-900: #1b5e20ff;
$__color-branding-primary-scale-900: #272d4eff;
$__color-branding-primary-scale-800: #323d67ff;
$__color-branding-primary-scale-700: #394674ff;
$__color-branding-primary-scale-600: #41507fff;
$__color-branding-primary-scale-500: #475888ff;
$__color-branding-primary-scale-400: #627097ff;
$__color-branding-primary-scale-300: #7d89a9ff;
$__color-branding-primary-scale-200: #a0aac2ff;
$__color-branding-primary-scale-100: #c5cbdbff;
$__color-branding-primary-scale-50: #e8eaf0ff;
$__color-branding-secondary-scale-50: #e0f7faff;
$__color-branding-secondary-scale-100: #b2ebf3ff;
$__color-branding-secondary-scale-200: #80dfebff;
$__color-branding-secondary-scale-300: #4dd1e3ff;
$__color-branding-secondary-scale-400: #26c7dcff;
$__color-branding-secondary-scale-500: #00bed6ff;
$__color-branding-secondary-scale-600: #00adc3ff;
$__color-branding-secondary-scale-700: #0098a9ff;
$__color-branding-secondary-scale-800: #008491ff;
$__color-branding-secondary-scale-900: #006165ff;
$__color-branding-complementary-yellow: #ffba49ff;
$__color-branding-complementary-green1: #c5e7a1ff;
$__color-branding-complementary-green2: #ecffd9ff;
$__color-branding-complementary-blue1: #2e8ee8ff;
$__color-branding-complementary-blue2: #e5fcffff;
$__color-branding-complementary-grey1: #555765ff;
$__color-branding-complementary-custard: #fff3deff;
$__color-branding-complementary-red: #e62b2bff;
$__color-branding-complementary-grey2: #edededff;
$__color-branding-primary-transparent-900-8: #272d4e14;
$__color-branding-primary-transparent-900-12: #272d4e1f;
$__color-branding-primary-transparent-900-16: #272d4e29;
$__color-branding-secondary-transparent-500-8: #00bed614;
$__color-branding-secondary-transparent-500-12: #00bed61f;
$__color-branding-secondary-transparent-500-16: #00bed629;
$__color-neutral-transparent-0-8: #ffffff14;
$__color-neutral-transparent-0-12: #ffffff1f;
$__color-neutral-transparent-0-16: #ffffff29;
$__color-neutral-transparent-1000-8: #00000014;
$__color-neutral-transparent-1000-12: #0000001f;
$__color-neutral-transparent-1000-16: #00000029;
$__size-8-scale-0: 0px;
$__size-8-scale-0-5: 4px;
$__size-8-scale-1: 8px;
$__size-8-scale-2: 16px;
$__color-neutral-transparent-1000-25: #00000040;
$__size-8-scale-12-5: 100px;
$__size-8-scale-0-25: 2px;
$__size-8-scale-1-5: 12px;
$__size-8-scale-3: 24px;
$__size-8-scale-4: 32px;
$__size-8-scale-5: 40px;
$__size-8-scale-6: 48px;
$__size-8-scale-7: 56px;
$__size-8-scale-8: 64px;
$__size-8-scale-9: 72px;
$__size-8-scale-10: 80px;
$__size-8-scale-2-25: 18px;
$__size-8-scale-2-5: 20px;
$__size-8-scale-5-5: 44px;
$__size-8-scale-12: 96px;
$__size-8-scale-1-25: 10px;
$__size-8-scale-4-25: 34px;
$__size-8-scale-7-5: 60px;
$__size-8-scale-14: 112px;
$__size-8-scale-1-75: 14px;
$__size-100-scale-3: 300px;
$__size-100-scale-4: 400px;
$__size-100-scale-5: 500px;
$__size-100-scale-7: 700px;
$__size-fraction-1-5: -1.5px;
$__size-fraction-0-5: -0.5px;
$__size-fraction-0: 0px;
$__size-fraction-0-1: 0.10000000149011612px;
$__size-fraction-0-15: 0.15000000596046448px;
$__size-fraction-0-25: 0.25px;
$__size-fraction-0-4: 0.4000000059604645px;
$__size-fraction-0-5: 0.5px;
$__size-fraction-1-25: 1.25px;
$__size-fraction-1-5: 1.5px;
$__size-8-scale-4-5: 36px;
$__color-neutral-transparent-1000-14: #00000024;
$__color-neutral-transparent-1000-20: #00000033;
$__size-8-scale-0-125: 1px;

// Semantic tokens
$text-on-light-secondary: $__color-neutral-scale-600;
$text-primary: $__color-branding-primary-scale-900;
$text-on-light-primary: $__color-neutral-scale-900;
$text-on-primary: $__color-neutral-scale-0;
$background-primary: $__color-neutral-scale-0;
$background-secondary: $__color-neutral-scale-100;
$surface-brand-primary: $__color-branding-primary-scale-900;
$container-interactive-primary: $__color-branding-primary-scale-900;
$container-interactive-secondary: $__color-branding-secondary-scale-500;
$state-layer-ligh-hover: $__color-neutral-transparent-0-8;
$state-layer-light-focus: $__color-neutral-transparent-0-12;
$state-layer-light-pressed: $__color-neutral-transparent-0-16;
$border-default: $__color-neutral-scale-300;
$text-on-error: $__color-neutral-scale-0;
$divider-default: $__color-neutral-scale-300;
$icon-on-primary: $__color-neutral-scale-0;
$icon-on-light-primary: $__color-neutral-scale-900;
$state-layer-dark-hover-low: $__color-neutral-transparent-1000-8;
$state-layer-dark-focus-medium: $__color-neutral-transparent-1000-12;
$state-layer-dark-pressed-high: $__color-neutral-transparent-1000-16;
$container-interactive-disabled: $__color-neutral-scale-300;
$text-error: $__color-error-scale-600;
$text-disabled: $__color-neutral-scale-500;
$icon-disabled: $__color-neutral-scale-500;
$icon-error: $__color-error-scale-500;
$divider-error: $__color-error-scale-500;
$container-static-error: $__color-error-scale-500;
$container-static-success: $__color-confirmation-scale-500;
$container-static-light-300: $__color-neutral-scale-300;
$divider-active: $__color-branding-primary-scale-900;
$icon-on-light-secondary: $__color-neutral-scale-600;
$icon-primary: $__color-branding-primary-scale-900;
$surface-inverse: $__color-neutral-scale-800;
$state-layer-primary-hover-low: $__color-branding-primary-transparent-900-8;
$state-layer-primary-focus-medium: $__color-branding-primary-transparent-900-12;
$state-layer-primary-pressed-high: $__color-branding-primary-transparent-900-16;
$state-layer-secondary-hover-low: $__color-branding-secondary-transparent-500-8;
$state-layer-secondary-focus-medium: $__color-branding-secondary-transparent-500-12;
$state-layer-secondary-pressed-high: $__color-branding-secondary-transparent-500-16;
$container-static-secondary-light: $__color-branding-secondary-scale-100;
$surface-light-0: $__color-neutral-scale-0;
$container-static-warning: $__color-warning-scale-500;
$background-overlay: $__color-neutral-transparent-1000-25;
$container-interactive-dark-800: $__color-neutral-scale-800;
$container-interactive-light-300: $__color-neutral-scale-300;
$icon-warning: $__color-warning-scale-500;
$border-strong: $__color-neutral-scale-500;
$text-warning: $__color-warning-scale-600;
$text-success: $__color-confirmation-scale-600;
$icon-success: $__color-confirmation-scale-500;
$container-interactive-destructive: $__color-error-scale-500;
$surface-light-100: $__color-neutral-scale-100;
$container-static-warning-light: $__color-warning-scale-50;
$container-static-error-light: $__color-error-scale-50;
$container-interactive-primary-100: $__color-branding-primary-scale-100;
$container-static-success-light: $__color-confirmation-scale-50;
$text-secondary: $__color-branding-secondary-scale-500;
$size-corner-radius-0: $__size-8-scale-0;
$text-on-secondary: $__color-neutral-scale-900;
$icon-on-secondary: $__color-neutral-scale-900;
$icon-on-error: $__color-neutral-scale-0;
$text-on-dark-primary: $__color-neutral-scale-0;
$size-corner-radius-s: $__size-8-scale-0-5;
$size-corner-radius-m: $__size-8-scale-1;
$size-corner-radius-l: $__size-8-scale-2;
$size-corner-radius-infinite: $__size-8-scale-12-5;
$size-corner-radius-xl: $__size-8-scale-6;
$size-icon-s: $__size-8-scale-2-5;
$size-icon-m: $__size-8-scale-3;
$size-component-s: $__size-8-scale-5;
$size-component-m: $__size-8-scale-5-5;
$size-component-l: $__size-8-scale-6;
$size-component-xs: $__size-8-scale-4;
$size-component-xl: $__size-8-scale-7;
$size-component-xxl: $__size-8-scale-9;
$size-spacing-xs: $__size-8-scale-0-5;
$size-spacing-s: $__size-8-scale-1;
$size-spacing-m: $__size-8-scale-2;
$size-spacing-l: $__size-8-scale-3;
$size-spacing-xl: $__size-8-scale-4;
$size-spacing-xxl: $__size-8-scale-5;
$size-corner-radius-xs: $__size-8-scale-0-25;
$container-static-light-0: $__color-neutral-scale-0;
$container-static-primary: $__color-branding-primary-scale-900;
$container-static-light-200: $__color-neutral-scale-200;
$container-interactive-primary-50: $__color-branding-primary-scale-50;
$size-text-font-size-xxs: $__size-8-scale-1-25;
$size-text-font-size-xs: $__size-8-scale-1-5;
$size-text-font-size-s: $__size-8-scale-1-75;
$size-text-font-size-m: $__size-8-scale-2;
$size-text-font-size-l: $__size-8-scale-2-5;
$size-text-font-size-xl: $__size-8-scale-3;
$size-text-font-size-2-xl: $__size-8-scale-4-25;
$size-text-font-size-3-xl: $__size-8-scale-6;
$size-text-font-size-4-xl: $__size-8-scale-7-5;
$size-text-font-size-5-xl: $__size-8-scale-12;
$size-text-line-weight-light: $__size-100-scale-3;
$size-text-line-weight-regular: $__size-100-scale-4;
$size-text-line-weight-medium: $__size-100-scale-5;
$size-text-line-weight-bold: $__size-100-scale-7;
$size-text-letter-spacing-xs: $__size-fraction-1-5;
$size-text-letter-spacing-s: $__size-fraction-0-5;
$size-text-letter-spacing-0: $__size-fraction-0;
$size-text-letter-spacing-xs: $__size-fraction-0-1;
$size-text-letter-spacing-s: $__size-fraction-0-15;
$size-text-letter-spacing-m: $__size-fraction-0-25;
$size-text-letter-spacing-l: $__size-fraction-0-4;
$size-text-letter-spacing-xl: $__size-fraction-0-5;
$size-text-letter-spacing-2-xl: $__size-fraction-1-25;
$size-text-letter-spacing-3-xl: $__size-fraction-1-5;
$size-text-line-height-0: $__size-8-scale-0;
$size-text-line-height-xs: $__size-8-scale-2;
$size-text-line-height-s: $__size-8-scale-2-5;
$size-text-line-height-m: $__size-8-scale-3;
$size-text-line-height-l: $__size-8-scale-4-5;
$size-text-line-height-xl: $__size-8-scale-7;
$size-text-line-height-2-xl: $__size-8-scale-9;
$size-text-line-height-3-xl: $__size-8-scale-14;
$container-interactive-light-0: $__color-neutral-scale-0;
$container-interactive-light-200: $__color-neutral-scale-200;
$effects-low-12: $__color-neutral-transparent-1000-12;
$effects-medium-14: $__color-neutral-transparent-1000-14;
$effects-high-20: $__color-neutral-transparent-1000-20;
$size-border-s: $__size-8-scale-0-125;
$size-border-m: $__size-8-scale-0-25;
