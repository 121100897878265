@import '../variables/semantic-tokens';

.mat-mdc-progress-spinner.neutral-spinner
	.mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner.neutral-spinner
	.mdc-circular-progress__indeterminate-circle-graphic {
	stroke: $text-on-light-primary;
}

.mat-mdc-progress-spinner.secondary-spinner
	.mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner.secondary-spinner
	.mdc-circular-progress__indeterminate-circle-graphic {
	stroke: $container-interactive-secondary;
}
